<script>
  import { Link } from "svelte-routing";
  import Logo from "static/Logo.svelte";
</script>

<style>
  .logo-icon {
    width: 271px;
    height: 46px;
    margin-bottom: 20px;
  }
</style>

<div class="logo-icon">
  <Link to=".">
    <Logo />
  </Link>
</div>
