<script>
  import Button from "components/Common/Buttons/Button.svelte";
</script>

<style>
  .link:hover {
    cursor: pointer;
    color: #2196f3;
    text-decoration: none;
  }
  .actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .personal-data {
    text-align: center;
  }
  @media (max-width: 767px) {
    .container {
      width: 100%;
    }
  }
</style>

<div class="actions">
  <div class="container">
    <Button type="submit">Отправить заявку</Button>
  </div>
  <div class="personal-data">
    Отправляя заявку, Вы соглашаетесь на&nbsp;
    <a
      class="link"
      target="_blank"
      href="https://astral.ru/soglashenie-na-obrabotku-personalnykh-dannykh/">
      обработку персональных данных
    </a>
  </div>
</div>
