<script>
  import LinkArrowRightIcon from "static/LinkArrowRightIcon.svelte";
  import Placeholder404 from "static/Placeholder404.svelte";
</script>

<style>
  .page-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding: 36px 0;
    overflow: hidden;
  }

  .content-wrapper {
    flex: 1;
    align-items: center;
    display: flex;
  }
  .content {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex: 1;
  }
  .section {
    display: flex;
    flex-direction: column;
    max-width: 500px;
  }
  .title {
    font-size: 34px;
    font-weight: bold;
    color: #072d57;
    margin-bottom: 10px;
  }
  .redirect {
    color: rgb(0, 136, 204);
    font-size: 16px;
  }
  .link-container:visited {
    color: rgb(0, 136, 204);
  }
  .link-container:hover {
    color: #006da3;
  }
  .link-container {
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }

  .link {
    margin-right: 12px;
  }
  .arrow-icon {
    display: flex;
  }
  :global(.arrow-icon svg) {
    width: 24px;
    height: 24px;
    fill: rgb(0, 136, 204);
  }
  :global(.link-container:hover .arrow-icon svg) {
    fill: #006da3;
  }
  :global(.image-wrapper svg) {
    width: 500px;
    height: auto;
    fill: none;
  }

  @media (max-width: 1200px) {
    .section {
      max-width: 400px;
    }
    .title {
      font-size: 28px;
    }
    :global(.image-wrapper svg) {
      width: 400px;
    }
  }
  @media (max-width: 992px) {
    .section {
      max-width: 300px;
    }
    .title {
      font-size: 20px;
    }
    .redirect {
      font-size: 14px;
    }
    :global(.image-wrapper svg) {
      width: 300px;
    }
  }
  @media (max-width: 768px) {
    .content {
      flex-direction: column-reverse;
      align-items: center;
    }
    .section {
      max-width: 75%;
    }
    .title {
      font-size: 18px;
      text-align: center;
    }
    .link-container {
      justify-content: center;
    }
    :global(.image-wrapper svg) {
      width: 100%;
    }
  }
  @media (max-width: 450px) {
    .title {
      font-size: 14px;
    }
    .redirect {
      font-size: 12px;
    }
  }
  @media (max-width: 350px) {
    .redirect {
      font-size: 10px;
    }
  }
</style>

<div class="page-wrapper">
  <div class="content-wrapper">
    <div class="content">
      <div class="section">
        <div class="title">Страница, которую Вы запрашиваете, не найдена</div>
        <div class="redirect">
          <a class="link-container" href="/">
            <div class="link">Вернутся на главную страницу</div>
            <div class="arrow-icon">
              <LinkArrowRightIcon />
            </div>
          </a>
        </div>
      </div>
      <div class="image-wrapper">
        <Placeholder404 />
      </div>
    </div>
  </div>
</div>
