<style>
  .back_to_top {
    background: #000;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    bottom: 20px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    color: #fff;
    cursor: pointer;
    height: 45px;
    opacity: 0;
    outline: 0;
    position: fixed;
    right: 20px;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-transition: bottom 0.2s, opacity 0.2s;
    -o-transition: bottom 0.2s, opacity 0.2s;
    -moz-transition: bottom 0.2s, opacity 0.2s;
    transition: bottom 0.2s, opacity 0.2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 45px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  :global(.back_to_top.back_to_top_show) {
    opacity: 0.8;
  }
  :global(.back_to_top.back_to_top_show:hover) {
    opacity: 1;
  }
  .back_to_top svg {
    display: block;
    height: 16px;
    width: 16px;
  }
</style>

<div title="Вернуться наверх" class="back_to_top">
  <svg class="icon" viewBox="0 0 16 16">
    <title>Вернуться наверх</title>
    <g stroke-width="1" stroke="currentColor">
      <polyline
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        points="15.5,11.5 8,4 0.5,11.5 " />
    </g>
  </svg>
</div>
