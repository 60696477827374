<script>
  import ContentWrapper from "components/ContentWrapper.svelte";
  import LeftFooter from "./BottomFooter/LeftFooter.svelte";
  import RightFooter from "./BottomFooter/RightFooter.svelte";
</script>

<style>
  .footer-width {
    max-width: 1400px;
    margin: auto;
  }
  .footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    .footer-width {
      max-width: 568px;
      margin: auto;
    }
    .footer-container {
      padding: 25px 0;
      justify-content: center;
    }
  }
  @media (min-width: 768px) {
    .footer-width {
      max-width: 768px;
      margin: auto;
    }
    .footer-container {
      padding: 25px 0;
      justify-content: space-between;
    }
  }
  @media (min-width: 992px) {
    .footer-width {
      max-width: 992px;
      margin: auto;
    }
    .footer-container {
      padding: 25px 0;
      justify-content: space-between;
    }
  }
  @media (min-width: 1200px) {
    .footer-width {
      max-width: 1200px;
      margin: auto;
    }
  }
  @media (min-width: 1400px) {
    .footer-width {
      max-width: 1400px;
      margin: auto;
    }
  }
</style>

<div class="footer-width">
  <ContentWrapper>
    <div class="footer-container">
      <LeftFooter />
      <RightFooter />
    </div>
  </ContentWrapper>
</div>
