<script>
  import Divider from "components/Common/Divider.svelte";
  import TopFooter from "components/Footer/TopFooter.svelte";
  import BottomFooter from "components/Footer/BottomFooter.svelte";
</script>

<style>
  .container {
    background-color: #274369;
    flex: 0 0 auto;
  }
  .divider {
  }
</style>

<div class="container w-100">
  <TopFooter />
  <div class="divider">
    <Divider classes="white-divider" />
  </div>
  <BottomFooter />
</div>
