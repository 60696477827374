<style>
  .tab_wrapper {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    background: white;
  }
  .tab_wrapper .tab_reel {
    flex-direction: row;
    direction: ltr;
    display: flex;
    transition: all 0.15s cubic-bezier(0.16, 1, 0.35, 1);
  }
  :global(.tab_wrapper .tab_reel > *) {
    width: 100%;
    word-break: break-word;
    height: 0;
    flex-shrink: 0;
    overflow: hidden;
  }
  .about-info {
    padding: 0 100px;
  }
</style>

<div>
  <div class="tab_wrapper">
    <div class="tab_reel" id="reel">
      <slot />
    </div>
  </div>
</div>
