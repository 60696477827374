<style>
  .text-wrapper {
    width: 100%;
  }
  :global(.text-wrapper p > img) {
    max-width: 100%;
    margin: auto;
    display: block;
  }
  :global(.text-wrapper h2 > a) {
    margin-left: -20px;
  }
  :global(.text-wrapper h3 > a) {
    margin-left: -20px;
  }
  :global(.text-wrapper h4 > a) {
    margin-left: -20px;
  }
  :global(.text-wrapper h5 > a) {
    margin-left: -20px;
  }
  :global(.text-wrapper h2) {
    font-size: 22px;
    margin-bottom: 8px;
    margin-top: 0;
    line-height: initial;
  }
  :global(.text-wrapper p) {
    margin: 12px 0;
    position: relative;
  }
  :global(.text-wrapper h3, .text-wrapper h4, .text-wrapper h5) {
    margin-bottom: 24px;
    margin-top: 0;
    line-height: initial;
  }
  :global(a.link-button) {
    margin: 12px 0;
  }
  @media (max-width: 767px) {
    .text-wrapper {
      max-width: 720px;
      padding: 0 24px;
      margin: auto;
    }
  }
  @media (min-width: 768px) {
    .text-wrapper {
      max-width: 720px;
      padding: 0 24px;
      margin: auto;
    }
  }
  @media (min-width: 992px) {
    .text-wrapper {
      max-width: 520px;
      padding: 0;
      margin: auto;
    }
  }
  @media (min-width: 1200px) {
    .text-wrapper {
      max-width: 720px;
      margin: auto;
      padding: 0;
    }
  }
  @media (min-width: 1400px) {
    .text-wrapper {
      max-width: 920px;
      margin: auto;
      padding: 0;
    }
  }
</style>

<div class="text-wrapper">
  <slot />
</div>
