<script>
  import PlatformLogo from "static/PlatfornLogo.svelte";
</script>

<style>
  .container {
    display: flex;
    align-items: flex-start;
  }
  .header-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .header-title-1 {
    color: #ffffff;
    font-weight: 300;
    font-size: 50px;
    display: flex;
    align-items: center;
  }
  .header-title-2 {
    font-weight: normal;
    font-size: 20px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  .logo-wrapper {
    width: 100px;
    height: 100px;
    margin: 0 24px;
  }
  @media (max-width: 767px) {
    .logo-wrapper {
      width: 55px;
      height: 55px;
      margin: 0;
    }
    .container {
      flex-direction: column;
      margin: 24px 0;
      align-items: center;
    }
    .header-title {
      align-items: center;
    }
    .header-title-1 {
      font-size: 24px;
      text-align: center;
    }
    .header-title-2 {
      font-size: 14px;
      text-align: center;
    }
  }
  @media (max-width: 992px) {
    .logo-wrapper {
      width: 74px;
      height: 74px;
      margin: 6px 12px;
    }
    .container {
      margin: 24px 0;
    }
    .header-title-1 {
      font-size: 32px;
    }
    .header-title-2 {
      font-size: 14px;
    }
  }
  @media (min-width: 992px) {
    .container {
      margin: 24px 0;
    }
    .logo-wrapper {
      width: 74px;
      height: 74px;
      margin: 6px 12px;
    }
    .header-title-1 {
      font-size: 32px;
    }
    .header-title-2 {
      font-size: 16px;
    }
  }
  @media (min-width: 1200px) {
    .logo-wrapper {
      width: 100px;
      height: 100px;
      margin: 6px 12px;
    }
    .container {
      margin: 24px 0;
    }
    .header-title-1 {
      font-size: 44px;
    }
    .header-title-2 {
      font-size: 18px;
    }
  }
  @media (min-width: 1400px) {
    .container {
      margin: 24px 12px 24px 0;
    }
    .header-title-1 {
      font-size: 48px;
    }
    .header-title-2 {
      font-size: 18px;
    }
  }
</style>

<div class="container">
  <div class="logo-wrapper">
    <PlatformLogo />
  </div>
  <div class="header-title">
    <div class="header-title-1">Астрал.Платформа —</div>
    <div class="header-title-2">
      Надёжный инструмент для развития вашего бизнеса
    </div>
  </div>
</div>
